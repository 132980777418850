import React, { useState, useEffect, FC } from "react";

import styles from "./Hero.module.scss";
import Button from "../Button";
import VideoPlayer from "../VideoPlayer";

const PLAY_BUTTON_DELAY = 1300;

const Hero: FC = () => {
  const [renderPlayButton, setRenderPlayButton] = useState(false);
  const [playVideo, setPlayVideo] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setRenderPlayButton(true);
    }, PLAY_BUTTON_DELAY);
  }, []);

  return (
    <section className={styles.hero}>
      <div className={styles.heroBg} />
      <div className={styles.heroContentArea}>
        <div className={styles.heroContent}>
          <h2 className={styles.caption}>
            <span>
              A retirement dream <br /> come true.
            </span>
          </h2>
          <h5 className={styles.subCaption}>
            <span>Watch the Weber's story</span>
          </h5>
          {renderPlayButton && (
            <Button playButton onClick={() => setPlayVideo(true)} />
          )}
        </div>
      </div>
      {playVideo && (
        <VideoPlayer
          src="videos/the-webers.mp4"
          onClose={() => setPlayVideo(false)}
        />
      )}
    </section>
  );
};

export default Hero;
