import React, { FC } from "react";

import styles from "./Header.module.scss";
import Logo from "../Logo";
import Button, { ButtonSize } from "../Button";

const Header: FC = () => (
  <header className={styles.header}>
    <nav className={styles.headerNav}>
      <Logo className={styles.logo} stories />
      <div className={styles.menu}>
        <ul>
          <li>Podcasts</li>
          <li>Newsletter</li>
          <li>Brand</li>
        </ul>
        <Button size={ButtonSize.sm} className={styles.accountButton}>
          Get a pension
        </Button>
      </div>
    </nav>
  </header>
);

export default Header;
