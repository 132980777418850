import React, { FC } from "react";
import "intersection-observer";
import "normalize.css";
import "./App.scss";

import Landing from "./components/Landing";
import OffScreen from "./components/OffScreen";
import Footer from "./components/Footer";

const App: FC = () => (
  <>
    <Landing />
    <OffScreen />
    <Footer />
  </>
);

export default App;
