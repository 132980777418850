import React, { useRef, useState, useEffect, FC } from "react";
import cx from "classnames";

import styles from "./QualityTime.module.scss";
import useIntersectorObserver from "../../hooks/useIntersectorObserver";

const COVER_LOWER_INTERSECTOR_THRESHOLD = 0;
const COVER_UPPER_INTERSECTOR_THRESHOLD = 0.6;

const QualityTime: FC = () => {
  const [isDetailsActive, setIsDetailsActive] = useState(false);
  const [isTimeActive, setIsTimeActive] = useState(false);
  const [isCoverOneActive, setIsCoverOneActive] = useState(false);
  const [isCoverTwoActive, setIsCoverTwoActive] = useState(false);
  const [isVideoCoverActive, setIsVideoCoverActive] = useState(false);
  const detailsRef = useRef<HTMLDivElement>(null);
  const timeRef = useRef<HTMLDivElement>(null);
  const coverOneRef = useRef<HTMLDivElement>(null);
  const coverTwoRef = useRef<HTMLDivElement>(null);
  const videoCoverRef = useRef<HTMLDivElement>(null);

  const {
    isIntersecting: detailsIsIntersecting,
    boundingClientRect: detailsBoundingClientRect,
  } = useIntersectorObserver({
    elRef: detailsRef,
  });

  const {
    isIntersecting: timeIsIntersecting,
    boundingClientRect: timeBoundingClientRect,
  } = useIntersectorObserver({
    elRef: timeRef,
  });

  const {
    isIntersecting: coverOneIsInterSecting,
    boundingClientRect: coverOneBoundingClientRect,
    intersectionRatio: coverOneIntersectionRatio,
  } = useIntersectorObserver({
    elRef: coverOneRef,
    threshold: [
      COVER_LOWER_INTERSECTOR_THRESHOLD,
      COVER_UPPER_INTERSECTOR_THRESHOLD,
    ],
  });

  const {
    isIntersecting: coverTwoIsInterSecting,
    boundingClientRect: coverTwoBoundingClientRect,
    intersectionRatio: coverTwoIntersectionRatio,
  } = useIntersectorObserver({
    elRef: coverTwoRef,
    threshold: [
      COVER_LOWER_INTERSECTOR_THRESHOLD,
      COVER_UPPER_INTERSECTOR_THRESHOLD,
    ],
  });

  const {
    isIntersecting: videoCoverIsInterSecting,
    boundingClientRect: videoCoverBoundingClientRect,
    intersectionRatio: videoCoverIntersectionRatio,
  } = useIntersectorObserver({
    elRef: videoCoverRef,
    threshold: [
      COVER_LOWER_INTERSECTOR_THRESHOLD,
      COVER_UPPER_INTERSECTOR_THRESHOLD,
    ],
  });

  useEffect(() => {
    if (detailsIsIntersecting) {
      setIsDetailsActive(true);
    }
    if (timeIsIntersecting) {
      setIsTimeActive(true);
    }
    if (
      coverOneIsInterSecting &&
      coverOneIntersectionRatio > COVER_UPPER_INTERSECTOR_THRESHOLD
    ) {
      setIsCoverOneActive(true);
    }
    if (
      coverTwoIsInterSecting &&
      coverTwoIntersectionRatio > COVER_UPPER_INTERSECTOR_THRESHOLD
    ) {
      setIsCoverTwoActive(true);
    }
    if (
      videoCoverIsInterSecting &&
      videoCoverIntersectionRatio > COVER_UPPER_INTERSECTOR_THRESHOLD
    ) {
      setIsVideoCoverActive(true);
    }
  }, [
    detailsIsIntersecting,
    timeIsIntersecting,
    coverOneIsInterSecting,
    coverOneIntersectionRatio,
    coverTwoIsInterSecting,
    coverTwoIntersectionRatio,
    videoCoverIsInterSecting,
    videoCoverIntersectionRatio,
  ]);

  useEffect(() => {
    if (!detailsIsIntersecting && detailsBoundingClientRect.top > 0) {
      setIsDetailsActive(false);
    }
    if (!timeIsIntersecting && timeBoundingClientRect.top > 0) {
      setIsTimeActive(false);
    }
    if (!coverOneIsInterSecting && coverOneBoundingClientRect.top > 0) {
      setIsCoverOneActive(false);
    }
    if (!coverTwoIsInterSecting && coverTwoBoundingClientRect.top > 0) {
      setIsCoverTwoActive(false);
    }
    if (!videoCoverIsInterSecting && videoCoverBoundingClientRect.top > 0) {
      setIsVideoCoverActive(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    detailsBoundingClientRect,
    timeBoundingClientRect,
    coverOneBoundingClientRect,
    coverTwoBoundingClientRect,
    videoCoverBoundingClientRect,
  ]);

  return (
    <section className={styles.qualityTime}>
      <div>
        <div>
          <div
            ref={coverOneRef}
            className={cx(styles.cover, styles.coverOne, {
              [styles.isActive]: isCoverOneActive,
            })}
          />
          <div
            ref={coverTwoRef}
            className={cx(styles.cover, styles.coverTwo, {
              [styles.isActive]: isCoverTwoActive,
            })}
          />
          <div
            ref={videoCoverRef}
            className={cx(styles.videoCover, {
              [styles.isActive]: isVideoCoverActive,
            })}
          >
            <div>
              <video preload="true" muted autoPlay disableRemotePlayback loop>
                <source src="videos/dancing-time.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
        <div className={styles.columnTwo}>
          <div className={styles.columnTwoContents}>
            <div
              ref={detailsRef}
              className={cx(styles.details, {
                [styles.isActive]: isDetailsActive,
              })}
            >
              <h2 className={styles.title}>
                Spend quality time enjoying shared hobbies with loved ones.
              </h2>
              <p className={styles.description}>
                In the 70's, the Weber's found love in music. In retirement,
                they can now spend most nights dancing into the early hours of
                the morning.
              </p>
            </div>
            <div
              ref={timeRef}
              className={cx(styles.time, {
                [styles.isActive]: isTimeActive,
              })}
            >
              <span className={styles.day}>
                <span
                  className={cx(styles.dayBlock, {
                    [styles.isInActive]: isVideoCoverActive,
                  })}
                >
                  Wednesday
                </span>
                <span
                  className={cx(styles.dayBlock, styles.dayBlockLater, {
                    [styles.isActive]: isVideoCoverActive,
                  })}
                >
                  Thursday
                </span>
              </span>
              <br />
              <span className={styles.hourTime}>
                <span
                  className={cx(styles.hourBlock, {
                    [styles.isInActive]: isCoverTwoActive,
                  })}
                >
                  <span className={styles.hourTimeChar}>1</span>
                  <span className={styles.hourTimeChar}>0</span>:
                  <span className={styles.hourTimeChar}>3</span>
                  <span className={styles.hourTimeChar}>6</span>
                </span>
                <span
                  className={cx(styles.hourBlock, styles.hourBlockLater, {
                    [styles.isActive]: isCoverTwoActive,
                    [styles.isInActive]: isVideoCoverActive,
                  })}
                >
                  <span className={styles.hourTimeChar}>1</span>
                  <span className={styles.hourTimeChar}>1</span>:
                  <span className={styles.hourTimeChar}>4</span>
                  <span className={styles.hourTimeChar}>2</span>
                </span>
                <span
                  className={cx(styles.hourBlock, styles.hourBlockLater, {
                    [styles.isActive]: isVideoCoverActive,
                  })}
                >
                  <span className={styles.hourTimeChar}>0</span>
                  <span className={styles.hourTimeChar}>0</span>:
                  <span className={styles.hourTimeChar}>1</span>
                  <span className={styles.hourTimeChar}>9</span>
                </span>
                <span className={styles.timePeriod}>
                  <span
                    className={cx(styles.periodBlock, {
                      [styles.isInActive]: isVideoCoverActive,
                    })}
                  >
                    <span className={styles.timePeriodChar}>P</span>
                    <span className={styles.timePeriodChar}>M</span>
                  </span>
                  <span
                    className={cx(styles.periodBlock, styles.periodBlockLater, {
                      [styles.isActive]: isVideoCoverActive,
                    })}
                  >
                    <span className={styles.timePeriodChar}>A</span>
                    <span className={styles.timePeriodChar}>M</span>
                  </span>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default QualityTime;
