import React, { FC } from "react";

const CloseIcon: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width="512"
    height="512"
  >
    <g
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    >
      <path d="M7 7L25 25"></path>
      <path d="M7 25L25 7"></path>
    </g>
  </svg>
);

export default CloseIcon;
