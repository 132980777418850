import { useState, useEffect } from "react";

import type { UseIntersectorObserverProps } from "./useIntersectorObserver.types";

const DEFAULT_THRESHOLD = 0;

const useIntersectorObserver = ({
  threshold = DEFAULT_THRESHOLD,
  elRef,
}: UseIntersectorObserverProps) => {
  const [isIntersecting, setIsintersecting] = useState(false);
  const [intersectionRatio, setIntersectionRatio] = useState(0);
  const [boundingClientRect, setBoundingClientRect] = useState<
    DOMRectReadOnly | Record<string, any>
  >({});

  const options = {
    threshold,
  };

  const callback = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      // Each entry describes an intersection change for one observed
      // target element:
      //   entry.boundingClientRect
      //   entry.intersectionRatio
      //   entry.intersectionRect
      //   entry.isIntersecting
      //   entry.rootBounds
      //   entry.target
      //   entry.time
      setIsintersecting(entry.isIntersecting);
      setIntersectionRatio(entry.intersectionRatio);
      setBoundingClientRect(entry.boundingClientRect);
    });
  };

  useEffect(() => {
    const el = elRef.current;
    const observer = new IntersectionObserver(callback, options);

    if (el) {
      observer.observe(el);
    }

    return () => {
      if (el) {
        observer.unobserve(el);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elRef]);

  return { isIntersecting, intersectionRatio, boundingClientRect };
};

export default useIntersectorObserver;
