import React, { FC } from "react";
import cx from "classnames";

import styles from "./Logo.module.scss";
import type { LogoProps } from "./Logo.types";

const Logo: FC<LogoProps> = ({ className, stories, inverse }) => (
  <span className={cx(styles.logo, className, { [styles.inverse]: inverse })}>
    APensionApp{stories && <span className={styles.stories}>stories</span>}
  </span>
);

export default Logo;
