import React, { FC } from "react";
import cx from "classnames";

import styles from "./Button.module.scss";
import { PlayIcon, PauseIcon } from "../../vectors";
import { ButtonProps, ButtonSize } from "./Button.types";

const buttonSizeClassNames = {
  [ButtonSize.sm]: styles.sm,
  [ButtonSize.md]: "",
  [ButtonSize.lg]: styles.lg,
};

const Button: FC<ButtonProps> = ({
  children,
  onClick,
  size = ButtonSize.md,
  className,
  playButton,
  playButtonInverse,
  isPlaying,
}) => (
  <button
    className={cx(styles.button, className, buttonSizeClassNames[size], {
      [styles.playButton]: playButton,
      [styles.playButtonInverse]: playButtonInverse,
      [styles.isPlaying]: isPlaying,
    })}
    onClick={onClick}
  >
    {playButton && (
      <span className={styles.buttonIcon}>
        <PlayIcon />
        <PauseIcon />
      </span>
    )}
    {!playButton && children}
  </button>
);

export default Button;
