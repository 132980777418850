import React, { useState, useEffect, useRef, MouseEvent, FC } from "react";
import cx from "classnames";

import styles from "./PhotosSpring.module.scss";
import loadSpringPhotos from "../../utils/loadSpringPhotos";
import type { PhotosSpringProps } from "./PhotosSpring.types";

const PHOTO_CLASSNAMES = [
  styles.pearl,
  styles.emerald,
  styles.diamond,
  styles.ruby,
  styles.jade,
  styles.amber,
  styles.turquoise,
  styles.sapphire,
  styles.opal,
];

const PHOTOS_LENGTH = 4;

const PhotosSpring: FC<PhotosSpringProps> = ({ isPassive }) => {
  const [photoClassNames, setPhotoClassNames] = useState(
    PHOTO_CLASSNAMES.slice(0, PHOTOS_LENGTH)
  );

  const containerRef = useRef<HTMLDivElement>(null);
  const hotspotRef = useRef<HTMLDivElement>(null);

  const photoOneRef = useRef<HTMLDivElement>(null);
  const photoTwoRef = useRef<HTMLDivElement>(null);
  const photoThreeRef = useRef<HTMLDivElement>(null);
  const photoFourRef = useRef<HTMLDivElement>(null);
  const photosRef = [photoOneRef, photoTwoRef, photoThreeRef, photoFourRef];

  const isActive = useRef(false);
  const targetDistanceX = useRef(0);
  const currDistanceX = useRef(0);

  const onMouseMoveHotspot = (e: MouseEvent<HTMLDivElement>) => {
    if (!containerRef.current || !hotspotRef.current) {
      return;
    }
    const { x } = containerRef.current.getBoundingClientRect();
    const switchPoint = hotspotRef.current.getBoundingClientRect().width / 3.5;
    const left = Math.max(e.clientX - x, 0);
    targetDistanceX.current = left - switchPoint;
  };

  const onMouseEnterHotSpot = () => {
    isActive.current = true;
    requestAnimationFrame(animatePhotos);
  };

  const onMouseLeaveHotspot = () => {
    isActive.current = false;
    targetDistanceX.current = 0;
  };

  const animatePhotos = () => {
    const speed = isActive.current ? 0.1 : 0.05;
    currDistanceX.current +=
      (targetDistanceX.current - currDistanceX.current) * speed;
    const x = parseFloat(currDistanceX.current.toFixed(2));

    photosRef.forEach((ref, index) => {
      if (ref.current) {
        const stretch = (4 - index) * 0.65;
        let translateX = parseFloat(
          (index * 40 + x * 0.3 * stretch).toFixed(2)
        );
        const scale = 1 - 0.1 * index;
        ref.current.style.transform = `translate3d(${translateX}px, 0, 0) scale3d(${scale}, ${scale}, ${scale})`;
      }
    });

    if (isActive.current || x !== 0) {
      requestAnimationFrame(animatePhotos);
    }
  };

  useEffect(() => {
    if (isPassive) {
      PHOTO_CLASSNAMES.sort(() => Math.random() - 0.5);
      setPhotoClassNames(PHOTO_CLASSNAMES.slice(0, PHOTOS_LENGTH));
      targetDistanceX.current = 0;
    }
  }, [isPassive]);

  useEffect(() => {
    loadSpringPhotos();
  }, []);

  return (
    <div className={styles.photosSpring} ref={containerRef}>
      {!isPassive && (
        <>
          {photoClassNames.map((className, index) => (
            <div key={className} className={styles.photoContainer}>
              <div
                ref={photosRef[index]}
                className={cx(styles.photo, className)}
              />
            </div>
          ))}
          <div
            ref={hotspotRef}
            onMouseEnter={onMouseEnterHotSpot}
            onMouseMove={onMouseMoveHotspot}
            onMouseLeave={onMouseLeaveHotspot}
            className={styles.hotspot}
          />
        </>
      )}
    </div>
  );
};

export default PhotosSpring;
