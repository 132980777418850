import React, { useState, useEffect, useRef, FC } from "react";
import { createPortal } from "react-dom";
import cx from "classnames";

import styles from "./VideoPlayer.module.scss";
import Button, { ButtonSize } from "../Button";
import { CloseIcon } from "../../vectors";
import type { VideoPlayerProps } from "./VideoPlayer.types";

const DEFAULT_TYPE = "video/mp4";
const FADE_CONTROLS_TIMEOUT = 5000;
const INITIAL_CONTROLS_TIMEOUT = 100;

const VideoPlayer: FC<VideoPlayerProps> = ({
  src,
  onClose,
  type = DEFAULT_TYPE,
}) => {
  const fadeControlsTimeout = useRef<NodeJS.Timeout>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isControlsActive, setIsControlsActive] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const progressRef = useRef<HTMLDivElement>(null);

  const onTimeUpdate = () => {
    if (!progressRef.current || !videoRef.current) {
      return;
    }
    const progress = videoRef.current.currentTime / videoRef.current.duration;
    progressRef.current.style.transform = `scale3d(${progress.toFixed(
      2
    )}, 1, 1)`;
  };

  const togglePlay = () => {
    if (!videoRef.current) {
      return;
    }

    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
  };

  const fadeControls = () => {
    return setTimeout(() => setIsControlsActive(false), FADE_CONTROLS_TIMEOUT);
  };

  const onInteract = () => {
    if (!fadeControlsTimeout.current) {
      return;
    }

    clearTimeout(fadeControlsTimeout.current);
    setIsControlsActive(true);
    fadeControlsTimeout.current = fadeControls();
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    fadeControlsTimeout.current = fadeControls();
    setTimeout(() => setIsControlsActive(true), INITIAL_CONTROLS_TIMEOUT);
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  return createPortal(
    <div
      className={cx(styles.videoPlayer, {
        [styles.isControlsActive]: isControlsActive,
      })}
      onMouseMove={onInteract}
      onClick={onInteract}
    >
      <span className={styles.close} onClick={onClose}>
        <CloseIcon />
      </span>
      <div className={styles.videoWrapper}>
        <video
          ref={videoRef}
          autoPlay
          disableRemotePlayback
          preload="true"
          onTimeUpdate={onTimeUpdate}
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
        >
          <source src={src} type={type} />
        </video>
      </div>
      <div className={styles.controls}>
        <div className={styles.playButton}>
          <Button
            playButton
            playButtonInverse
            isPlaying={isPlaying}
            size={ButtonSize.sm}
            onClick={togglePlay}
          />
        </div>
        <div className={styles.progressBar}>
          <div ref={progressRef} className={styles.currentProgress} />
        </div>
      </div>
    </div>,
    document.body
  );
};

export default VideoPlayer;
