import React, { FC } from 'react';

import styles from './Landing.module.scss';
import Header from '../Header'
import Hero from '../Hero'

const Landing:FC = () => (
  <div className={styles.landing}>
    <Header />
    <Hero />
  </div>
)

export default Landing;