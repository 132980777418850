import React, { FC } from "react";

const CNNLogo: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="900"
    height="420"
    viewBox="0 -2100 9000 4200"
  >
    <path
      fill="#c00"
      d="M2800-900h-700a1 1 0 000 1800h783a117 117 0 00117-117v-2066a817 817 0 011526-405l874 1529v-1124a817 817 0 011526-405l874 1529v-1941h500l100 100 100-100h500v3383a817 817 0 01-1526 405L6600 159v1124a817 817 0 01-1526 405L4200 159v1124a817 817 0 01-817 817H2100a1 1 0 010-4200h700v500l-100 100 100 100z"
    ></path>
    <path
      fill="none"
      stroke="#fff"
      strokeWidth="200"
      d="M2800-1500h-700a1500 1500 0 000 3000h1283a217 217 0 00217-217v-2566a217 217 0 01405-108l1590 2782a217 217 0 00405-108v-2566a217 217 0 01405-108l1590 2782a217 217 0 00405-108v-3383"
    ></path>
  </svg>
);

export default CNNLogo;
