import React, { FC } from "react";

import styles from "./OffScreen.module.scss";
import Passion from "../Passion";
import QualityTime from "../QualityTime";
import Testimonials from "../Testimonials";
import Join from "../Join";
import NextStory from "../NextStory";

const OffScreen: FC = () => (
  <div className={styles.offScreen}>
    <Passion />
    <QualityTime />
    <Testimonials />
    <Join />
    <NextStory />
  </div>
);

export default OffScreen;
