import React, { useState, useRef, useEffect, FC } from "react";
import cx from "classnames";

import styles from "./Join.module.scss";
import Button, { ButtonSize } from "../Button";
import Logo from "../Logo";
import PhotosSpring from "../PhotosSpring";
import useIntersectorObserver from "../../hooks/useIntersectorObserver";

const INTERSECTOR_THRESHOLD = 0.14;

const Join: FC = () => {
  const [isActive, setIsActive] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const { isIntersecting, boundingClientRect } = useIntersectorObserver({
    elRef: containerRef,
    threshold: INTERSECTOR_THRESHOLD,
  });

  useEffect(() => {
    if (isIntersecting) {
      setIsActive(true);
    }
  }, [isIntersecting]);

  useEffect(() => {
    if (!isIntersecting && boundingClientRect.top > 0) {
      setIsActive(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boundingClientRect]);

  return (
    <section
      ref={containerRef}
      className={cx(styles.join, { [styles.isActive]: isActive })}
    >
      <div className={styles.joinGrid}>
        <div>
          <Logo className={styles.logo} />
          <h4 className={styles.title}>
            Join over 10 million others saving for a reliable future.
          </h4>
          <div className={styles.actionButton}>
            <Button size={ButtonSize.md}>Learn more</Button>
          </div>
        </div>
        <div>
          <PhotosSpring isPassive={!isActive} />
        </div>
      </div>
    </section>
  );
};

export default Join;
