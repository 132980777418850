import pearl from "../assets/images/customer-pearl.jpg";
import emerald from "../assets/images/customer-emerald.jpg";
import diamond from "../assets/images/customer-diamond.jpg";
import ruby from "../assets/images/customer-ruby.jpg";
import jade from "../assets/images/customer-jade.jpg";
import amber from "../assets/images/customer-amber.jpg";
import turquoise from "../assets/images/customer-turquoise.jpg";
import sapphire from "../assets/images/customer-sapphire.jpg";
import opal from "../assets/images/customer-opal.jpg";

const PHOTOS = [
  pearl,
  emerald,
  diamond,
  ruby,
  jade,
  amber,
  turquoise,
  sapphire,
  opal,
];

const loadSpringPhotos = () => {
  PHOTOS.forEach((src) => {
    const img = new Image();
    img.src = src;
  });
};

export default loadSpringPhotos;
