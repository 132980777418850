import React, { FC } from "react";

const RakutenLogo: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    enableBackground="new 0 0 165.9 49.4"
    version="1.1"
    viewBox="0 0 165.9 49.4"
    xmlSpace="preserve"
  >
    <path d="M133.5 41.4L33.2 41.4 41.2 49.4z" fill="#bf0000"></path>
    <path
      d="M149.6 36.3v-16c0-3 2.1-5.5 5.1-5.5 3 0 5.1 2.5 5.1 5.5v16h6.2v-16c0-6.6-4.5-11.9-11.1-11.9-3 0-5.3 1.7-5.3 1.7V9h-6.2v27.2h6.2z"
      fill="#bf0000"
    ></path>
    <path
      d="M129.1 8.3c-7.2 0-12.3 6.3-12.3 14.3 0 8.4 6.4 14.3 12.9 14.3 3.3 0 7.4-1.1 10.9-6.1l-5.5-3.2c-4.2 6.2-11.3 3.1-12.1-3.2h17.8c1.6-9.6-4.7-16.1-11.7-16.1zm5.4 10.8h-11.1c1.3-6.3 9.9-6.7 11.1 0z"
      fill="#bf0000"
    ></path>
    <path
      d="M114.8 29.8c-.6.4-1.3.7-2.1.7-1 0-2.9-.8-2.9-3.3V15.5h5.3V9h-5.3V2.2h-6.2V9h-3.3v6.5h3.3v11.7c0 6.1 4.6 9.7 9.2 9.7 1.7 0 4.1-.6 6-1.7l-4-5.4z"
      fill="#bf0000"
    ></path>
    <path
      d="M92 9v16c0 3-2.1 5.5-5.1 5.5-3 0-5.1-2.5-5.1-5.5V9h-6.2v16c0 6.6 4.5 11.9 11.1 11.9 3 0 5.3-1.7 5.3-1.7v1h6.2V9H92z"
      fill="#bf0000"
    ></path>
    <path
      d="M64 21.8L74.6 9 66 9 58.5 18.5 58.5 0 52.2 0 52.2 36.3 58.5 36.3 58.5 25.1 67.7 36.3 76.3 36.3z"
      fill="#bf0000"
    ></path>
    <path
      d="M42.5 9v1.2C40.6 9 39 8.3 36.7 8.3c-7 0-12.4 6.4-12.4 14.3s5.3 14.3 12.4 14.3c2.3 0 4-.7 5.8-1.9v1.2h6.2V9h-6.2zm-5.9 21.4c-3.5 0-6-3.4-6-7.7 0-4.3 2.5-7.7 6-7.7s5.9 3.4 5.9 7.7c.1 4.3-2.4 7.7-5.9 7.7z"
      fill="#bf0000"
    ></path>
    <path
      d="M6.5 36.3V25.7H11l7.9 10.5H27l-9.6-12.7c3-2.1 4.9-5.6 4.9-9.6 0-6.5-5.3-11.7-11.7-11.7H0v34h6.5zm0-27.6h4.2c2.9 0 5.3 2.4 5.3 5.3s-2.4 5.3-5.3 5.3H6.5V8.7z"
      fill="#bf0000"
    ></path>
  </svg>
);

export default RakutenLogo;
