import React, { useState, useRef, useEffect, FC } from "react";
import cx from "classnames";

import styles from "./NextStory.module.scss";
import useIntersectorObserver from "../../hooks/useIntersectorObserver";
import { PaypalIcon } from "../../vectors";

const DETAILS_INTERSECTOR_THRESHOLD = 0.13;
const COVERS_LOWER_INTERSECTOR_THRESHOLD = 0;
const COVERS_UPPER_INTERSECTOR_THRESHOLD = 0.6;

const NextStory: FC = () => {
  const [isActive, setIsActive] = useState(false);
  const [IsCoversActive, setIsCoversActive] = useState(false);
  const detailsRef = useRef<HTMLDivElement>(null);
  const coversRef = useRef<HTMLDivElement>(null);

  const { isIntersecting, boundingClientRect } = useIntersectorObserver({
    elRef: detailsRef,
    threshold: DETAILS_INTERSECTOR_THRESHOLD,
  });

  const {
    isIntersecting: coversIsInterSecting,
    boundingClientRect: coversBoundingClientRect,
    intersectionRatio: coversIntersectionRatio,
  } = useIntersectorObserver({
    elRef: coversRef,
    threshold: [
      COVERS_LOWER_INTERSECTOR_THRESHOLD,
      COVERS_UPPER_INTERSECTOR_THRESHOLD,
    ],
  });

  useEffect(() => {
    if (isIntersecting) {
      setIsActive(true);
    }
    if (
      coversIsInterSecting &&
      coversIntersectionRatio > COVERS_UPPER_INTERSECTOR_THRESHOLD
    ) {
      setIsCoversActive(true);
    }
  }, [isIntersecting, coversIsInterSecting, coversIntersectionRatio]);

  useEffect(() => {
    if (!isIntersecting && boundingClientRect.top > 0) {
      setIsActive(false);
    }
    if (!coversIsInterSecting && coversBoundingClientRect.top > 0) {
      setIsCoversActive(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boundingClientRect, coversBoundingClientRect]);

  return (
    <section className={styles.nextStory}>
      <div
        className={cx(styles.nextStoryGrid, { [styles.isActive]: isActive })}
      >
        <div ref={detailsRef} className={styles.details}>
          <div>
            <h6 className={styles.subTitle}>Next story</h6>
            <h4 className={styles.title}>
              <span>Thomas Fischer's body building secrets.</span>
            </h4>
          </div>
          <div>
            <p className={styles.collaborator}>
              A collaboration with <br />
              <span className={styles.collaboratorLogo}>
                <PaypalIcon />
              </span>
            </p>
          </div>
        </div>
        <div
          ref={coversRef}
          className={cx(styles.covers, { [styles.isActive]: IsCoversActive })}
        >
          <div className={cx(styles.cover, styles.coverOne)} />
          <div className={cx(styles.cover, styles.coverTwo)} />
          <div className={cx(styles.cover, styles.coverThree)} />
        </div>
      </div>
    </section>
  );
};

export default NextStory;
