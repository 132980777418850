export enum ButtonSize {
  sm,
  md,
  lg,
}

export type ButtonProps = {
  onClick?: () => void;
  size?: ButtonSize;
  className?: string;
  playButton?: boolean;
  playButtonInverse?: boolean;
  isPlaying?: boolean;
};
