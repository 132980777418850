import React, { useState, useRef, useEffect, FC } from "react";
import cx from "classnames";

import styles from "./Testimonials.module.scss";
import useIntersectorObserver from "../../hooks/useIntersectorObserver";
import { CNNLogo, RedBullLogo, RakutenLogo } from "../../vectors";

const INTERSECTOR_THRESHOLD = 0.17;

const TESTIMONIALS = [
  {
    quote: "Save for your future, for less.",
    author: "Sarah Morrison",
    logo: <CNNLogo />,
  },
  {
    quote: "Easy to transfer or setup.",
    author: "Kevin Williams",
    logo: <RedBullLogo />,
  },
  {
    quote: "My future is in safe hands.",
    author: "Juliette Lambert",
    logo: <RakutenLogo />,
  },
];

const Testimonials: FC = () => {
  const [isActive, setIsActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const { isIntersecting, boundingClientRect } = useIntersectorObserver({
    elRef: containerRef,
    threshold: INTERSECTOR_THRESHOLD,
  });

  const onRingAnimationEnd = () => {
    const bumpedActiveIndex = activeIndex + 1;
    const nextActiveIndex =
      bumpedActiveIndex === TESTIMONIALS.length ? 0 : bumpedActiveIndex;
    setActiveIndex(nextActiveIndex);
  };

  useEffect(() => {
    if (isIntersecting) {
      setIsActive(true);
    }
  }, [isIntersecting]);

  useEffect(() => {
    if (!isIntersecting && boundingClientRect.top > 0) {
      setIsActive(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boundingClientRect]);

  return (
    <section
      ref={containerRef}
      className={cx(styles.testimonials, { [styles.isActive]: isActive })}
    >
      <div className={styles.testimonialsCarousel}>
        <div>
          <blockquote>
            <p className={styles.flipArea}>
              {TESTIMONIALS.map(({ quote }, index) => (
                <span
                  className={cx({
                    [styles.activeBlockItem]: index === activeIndex,
                  })}
                  key={index}
                >
                  {quote}
                </span>
              ))}
            </p>
            <footer>
              <figcaption className={styles.flipArea}>
                {TESTIMONIALS.map(({ author }, index) => (
                  <span
                    className={cx({
                      [styles.activeBlockItem]: index === activeIndex,
                    })}
                    key={index}
                  >
                    {author}
                  </span>
                ))}
              </figcaption>
              <div className={cx(styles.testimonialLogo, styles.flipArea)}>
                {TESTIMONIALS.map(({ logo }, index) => (
                  <span
                    className={cx({
                      [styles.activeBlockItem]: index === activeIndex,
                    })}
                    key={index}
                  >
                    {logo}
                  </span>
                ))}
              </div>
            </footer>
          </blockquote>
        </div>
        <div className={styles.carouselRings}>
          {TESTIMONIALS.map((item, index) => (
            <svg
              className={cx(styles.carouselRing, {
                [styles.activeRing]: index === activeIndex,
              })}
              viewBox="0 0 40 40"
              width="24"
              height="24"
              key={index}
            >
              <circle
                className={styles.backgroundRing}
                strokeWidth="5"
                fill="none"
                r="12"
                cx="20"
                cy="20"
              />
              <circle
                className={styles.progressRing}
                strokeWidth="5"
                strokeDasharray="80"
                fill="none"
                r="12"
                cx="20"
                cy="20"
                onAnimationEnd={onRingAnimationEnd}
              />
            </svg>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
