import React, { useRef, useState, useEffect, FC } from "react";
import cx from "classnames";

import styles from "./Passion.module.scss";
import useIntersectorObserver from "../../hooks/useIntersectorObserver";

const COVER_LOWER_INTERSECTOR_THRESHOLD = 0;
const COVER_UPPER_INTERSECTOR_THRESHOLD = 0.6;

const Passion: FC = () => {
  const [isTitleActive, setIsTitleActive] = useState(false);
  const [isCoverActive, setIsCoverActive] = useState(false);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const coverContainerRef = useRef<HTMLDivElement>(null);

  const {
    isIntersecting: titleIsIntersecting,
    boundingClientRect: titleBoundingClientRect,
  } = useIntersectorObserver({
    elRef: titleRef,
  });

  const {
    isIntersecting: coverIsInterSecting,
    boundingClientRect: coverBoundingClientRect,
    intersectionRatio: coverIntersectionRatio,
  } = useIntersectorObserver({
    elRef: coverContainerRef,
    threshold: [
      COVER_LOWER_INTERSECTOR_THRESHOLD,
      COVER_UPPER_INTERSECTOR_THRESHOLD,
    ],
  });

  useEffect(() => {
    if (titleIsIntersecting) {
      setIsTitleActive(true);
    }
    if (
      coverIsInterSecting &&
      coverIntersectionRatio > COVER_UPPER_INTERSECTOR_THRESHOLD
    ) {
      setIsCoverActive(true);
    }
  }, [titleIsIntersecting, coverIsInterSecting, coverIntersectionRatio]);

  useEffect(() => {
    if (!titleIsIntersecting && titleBoundingClientRect.top > 0) {
      setIsTitleActive(false);
    }
    if (!coverIsInterSecting && coverBoundingClientRect.top > 0) {
      setIsCoverActive(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titleBoundingClientRect, coverBoundingClientRect]);

  return (
    <section className={styles.passion}>
      <h2
        ref={titleRef}
        className={cx(styles.title, { [styles.isActive]: isTitleActive })}
      >
        <span>Rediscover your passion.</span>
      </h2>
      <div
        ref={coverContainerRef}
        className={cx(styles.passionCover, {
          [styles.isActive]: isCoverActive,
        })}
      />
    </section>
  );
};

export default Passion;
