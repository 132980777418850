import React, { FC } from "react";

import styles from "./Footer.module.scss";
import Logo from "../Logo";

const Footer: FC = () => (
  <footer className={styles.footer}>
    <nav className={styles.footerNav}>
      <div>
        <Logo inverse />
      </div>
      <ul className={styles.menuColumn}>
        <li>
          <span>Podcasts</span>
        </li>
        <li>
          <span>Newsletter</span>
        </li>
        <li>
          <span>Brand</span>
        </li>
        <li>
          <span>FAQ</span>
        </li>
      </ul>
      <ul className={styles.menuColumn}>
        <li>
          <span>Plans</span>
        </li>
        <li>
          <span>Terms and conditions</span>
        </li>
        <li>
          <span>Privacy Policy</span>
        </li>
        <li>
          <span>Careers</span>
        </li>
      </ul>
    </nav>
  </footer>
);

export default Footer;
